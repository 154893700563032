<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation>
			<ti-card :icon="$icons.user">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> User</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>

				</template>
				<template #title-actions-hidden>
<!--					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this user?" button-text="Delete">-->
						<!--						<v-btn color="error" elevation="2" fab small class="mr-4">-->
						<!--							<v-icon>{{ $icons.delete }}</v-icon>-->
						<!--						</v-btn>-->
<!--					</ti-confirm>-->
					<ti-confirm @confirm="destroy" :message="'Permanently destroy this user and all associated data? <br> <br> This can\'t be undone!'" button-text="Destroy">
						<v-btn color="black" elevation="2" fab small class="mr-4">
							<v-icon class="white--text">{{ $icons.skull }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>

				<ti-form-layout>
					<template #sidebar>
						<v-col cols="12">
							<v-select v-model="user.type" :items="userTypes" item-text="label" item-value="value" label="User Type"></v-select>
						</v-col>

						<v-col cols="12">
							<v-btn class="primary" @click="resetPassword" :loading="resetPasswordLoading">Reset password</v-btn>
							<div class="v-messages__message mt-4" style="line-height: 1.5">This will trigger a password reset email to be sent to the users email address. Valid for 24 hours.</div>
						</v-col>

						<v-col cols="12">
							<v-text-field v-model="user.password" label="New password" hint="Manually set a new password." persistent-hint :rules="validations.password"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-checkbox v-model="user.password_reset_flag" label="Flag for password reset" hint="This will trigger a password reset the next time the user tries to login." persistent-hint></v-checkbox>
						</v-col>

						<v-col cols="12">
							<v-checkbox v-model="user.is_corporate_admin" label="Corporate Admin"></v-checkbox>
						</v-col>

						<v-col cols="12">
							<v-checkbox v-model="user.test_user" label="Test user"></v-checkbox>
						</v-col>

						<v-col cols="12">
							<v-checkbox v-model="user.legacy_paypal" label="Legacy PayPal"></v-checkbox>
						</v-col>

						<v-col cols="12">
							<v-text-field readonly v-model="user.created_at" label="Created at"></v-text-field>
						</v-col>

					</template>

					<template>

						<v-col cols="12">
							<v-row>
								<v-col cols="12" lg="6">
									<v-text-field v-model="user.first_name" :rules="validations.name" label="First name"></v-text-field>
								</v-col>

								<v-col cols="12" lg="6">
									<v-text-field v-model="user.last_name" :rules="validations.name" label="Last name"></v-text-field>
								</v-col>

								<v-col cols="12" lg="6">
									<v-text-field v-model="user.alias" label="Alias"></v-text-field>
								</v-col>

								<v-col cols="12" lg="6">
									<v-text-field v-model="user.email" :rules="validations.email" label="Email"></v-text-field>
								</v-col>

								<v-col cols="12" lg="6">
									<v-text-field v-model="user.job_title" label="Job title"></v-text-field>
								</v-col>

								<v-col cols="12" lg="6">
									<v-text-field v-model="user.organization" label="Organization"></v-text-field>
								</v-col>

								<v-col cols="12" lg="6">
									<v-text-field v-model="user.country" label="Country"></v-text-field>
								</v-col>
							</v-row>
						</v-col>

						<v-col cols="12">
							<v-row>
								<v-col cols="12" lg="6">
									<h4 class="font-weight-light text-h4">
										Additional information
									</h4>
								</v-col>
							</v-row>
						</v-col>

						<v-col cols="12">
							<v-row>
								<v-col cols="12" lg="6">
									<v-select v-model="user.corporate_id" label="Corporate" :items="corporates" item-text="name" item-value="id"></v-select>
								</v-col>

								<v-col cols="12" lg="6">
									<v-checkbox v-model="user.interested_cim" label="Interested CIM"></v-checkbox>
								</v-col>

								<v-col cols="12" lg="6">
									<v-select v-model="user.membership_status" :items="membership_statuses" label="Membership status"></v-select>
								</v-col>

								<v-col cols="12" lg="6">
									<v-text-field v-model="user.stripe_customer_id" label="Stripe customer id"></v-text-field>
								</v-col>

								<v-col cols="12" lg="6">
									<v-checkbox v-model="user.override_subscription" label="Override subscription" hint="Allow this user to login is as if subscribed regardless of subscription / payment" persistent-hint></v-checkbox>
								</v-col>


								<v-col cols="12" lg="6" v-if="user.override_subscription">
									<ti-date-picker v-model="user.override_subscription_until" label="Override subscription until" hint="Only apply the subscription override until a specific date. Leaving blank will last forever." persistent-hint></ti-date-picker>
								</v-col>
							</v-row>
						</v-col>


					</template>

				</ti-form-layout>
			</ti-card>

			<ti-card :icon="$icons.corporate" v-if="user.corporate_id">
				<template #title>Corporate Custom Fields</template>
				<ti-form-layout>
					<template>
						<v-col cols="12" v-for="custom_field in user.corporate_custom_fields">
							<p>{{ custom_field.name }}: {{ custom_field.value }}</p>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>


			<ti-card :icon="$icons.corporate" v-if="user.corporate_id">
				<template #title>CIM Qualification terms</template>
				<ti-form-layout title="">
					<template>
						<v-col cols="12" lg="7">
							<v-row>
								<v-col cols="auto">
									<v-icon color="success" v-if="user.cim_term_minimum_skills">{{ $icons.check }}</v-icon>
									<v-icon color="error" v-else>{{ $icons.close }}</v-icon>
								</v-col>
								<v-col cols>
									<p>I understand I must meet the minimum experience/qualification and English skills requirements to undertake any of these awards below or the full qualification and will email my CV to susana@targetinternet.com before starting my qualification</p>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" lg="7">
							<v-row>
								<v-col cols="auto">
									<v-icon color="success" v-if="user.cim_term_fee">{{ $icons.check }}</v-icon>
									<v-icon color="error" v-else>{{ $icons.close }}</v-icon>
								</v-col>
								<v-col cols>
									<p>I understand that at the time of submitting each assignment I will be asked to pay £290 fee, on top of my subscription, to cover tutor support fees and CIM assignment fees.</p>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" lg="7">
							<v-row>
								<v-col cols="auto">
									<v-icon color="success" v-if="user.cim_term_membership">{{ $icons.check }}</v-icon>
									<v-icon color="error" v-else>{{ $icons.close }}</v-icon>
								</v-col>
								<v-col cols>
									<p>I understand that I must have an active CIM membership in order to be able to study for any of the awards below or for the full qualification and here is my CIM Membership number</p>
									<v-text-field v-model="user.cim_membership_number" label="CIM membership number"></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" lg="7">
							<v-row>
								<v-col cols="auto">
									<v-icon color="success" v-if="user.cim_term_special_needs">{{ $icons.check }}</v-icon>
									<v-icon color="secondary" v-else>{{ $icons.close }}</v-icon>
								</v-col>
								<v-col cols>
									<p>I have a special educational need I would like known on my record. I will contact susana@targetinternet.com with details of this before booking in for my assignment.</p>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" lg="7">
							<v-row>
								<v-col cols="auto">
									<v-icon color="success" v-if="user.cim_term_timescale">{{ $icons.check }}</v-icon>
									<v-icon color="error" v-else>{{ $icons.close }}</v-icon>
								</v-col>
								<v-col cols>
									<p>I understand that I must adhere to the assignment timescales on the learning plan below in order to be entered for each submission and I will complete the "Study Guide" module below.</p>
								</v-col>
							</v-row>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>

			<ti-card :icon="$icons.payments">
				<template #title>Payments</template>
				<ti-form-layout title="">
					<v-row>
						<v-col cols="12">
							<v-data-table
								:headers="payments_headers"
								:items="user.payments"
								:items-per-page="100"
							></v-data-table>
						</v-col>
					</v-row>
				</ti-form-layout>
			</ti-card>

			<ti-card :icon="$icons.courses">
				<template #title>Courses completed (xapi statements)</template>
				<ti-form-layout title="">
					<v-row>
						<v-col cols="12">
							<xapi-statements :user_id="user.id"></xapi-statements>
						</v-col>
					</v-row>
				</ti-form-layout>
			</ti-card>

			<ti-card :icon="$icons.xapi_content">
				<template #title>Latest Benchmark Result</template>
				<ti-form-layout title="">
					<v-row>
						<v-col cols="12">
							<benchmark-results :results="user.benchmark_results"></benchmark-results>
						</v-col>
					</v-row>
				</ti-form-layout>
			</ti-card>



			<ti-card :icon="$icons.user_groups">
				<template #title>Corporate user groups</template>
				<ti-form-layout title="">
						<v-col cols="12" lg="6">
							<v-select
							:items="user.available_user_groups"
							v-model="user.user_groups_attributes"
							label="User groups"
							item-text="name"
							item-value="id"
							multiple
							return-object
							></v-select>
						</v-col>
				</ti-form-layout>
			</ti-card>
		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import _forEach from 'lodash/forEach'
import {sync} from "vuex-pathify";
import models from '@/models'
import XapiStatements from "./XapiStatements"
import BenchmarkResults from "./BenchmarkResults"

export default {

	name: "UsersEdit",
	props: ['id'],
	components: {
		XapiStatements,
		BenchmarkResults
	},
	computed: {
		user: sync('users/user'),
		corporates: sync('corporates/corporates'),
		corporates_with_individual() {
			return [{name: 'No Corporate (Individual)', id: null}].concat(this.corporates)
		},
		isEdit() {
			return this.$route.meta.edit
		},
	},
	data: () => ({
		destroyDialog: false,
		valid: false,
		membership_statuses: ['Active Individual', 'Trial Individual', 'Cancelled Paid Individual', 'Cancelled Trial Individual', 'Active Corporate', 'Cancelled Corporate', 'Free Individual', 'Corporate Trial', 'Non Paying Individual', 'Benchmark Corporate'],
		showPassword: false,
		validations: {
			first_name: validationRules('First name', ['required', 'betweenLength:2:10']),
			last_name: validationRules('Last name', ['required']),
			email: validationRules('Email', ['required', 'email']),
			password: [(v) => (!v || v.length >= 8) || 'Password must be at least 8 characters',],
		},
		resetPasswordLoading: false,
		userTypes: [
			{
				label: 'Customer',
				value: 'Customer'
			},
			{
				label: 'Admin',
				value: 'AdminUser'
			}
		],
		payments_headers: [
			{
				text: 'ID',
				align: 'start',
				sortable: true,
				value: 'id',
			},
			{
				text: 'Provider',
				align: 'start',
				sortable: true,
				value: 'provider',
			},
			{
				text: 'Invoice id',
				align: 'start',
				sortable: true,
				value: 'invoice_id',
			},
			{
				text: 'Posted',
				align: 'start',
				sortable: true,
				value: 'created_at',
			},
			{
				text: 'Start',
				align: 'start',
				sortable: true,
				value: 'start_date',
			},
			{
				text: 'End',
				align: 'start',
				sortable: true,
				value: 'end_date',
			},
			{
				text: 'Amount',
				align: 'end',
				sortable: true,
				value: 'payment_amount',
			},

		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			if (this.isEdit) {
				this.$store.set('users/getUser!', this.id)
				this.$store.set('corporates/getCorporates!', null)
			} else {
				this.user = models.User()
			}
		},
		save() {
			if (this.$refs.form.validate()) {
				this.$api.users.update(this.id, this.user)
					.then(response => {
						this.$toast.add('User updated successfully', 'success')
						this.$route.meta.disableWarn = true;
						this.$router.push({name: 'Users'})
					})
					.catch(error => {
						console.log(error.response);
						this.$toast.handleResponseErrors(this, error)
					})
			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			if (this.isEdit) {
				this.$api.users.destroy(this.id)
					.then(response => {
						this.$toast.add('User destroyed successfully', 'success')
						this.$route.meta.disableWarn = true;
						this.$router.push({name: 'Users'})
					})
					.catch(error => {
						console.log(error.response);
						this.$toast.handleResponseErrors(this, error)
					})
			}
			else {
				this.$route.meta.disableWarn = true;
				this.$router.push({name: 'Users'})
			}
		},
		resetPassword() {
			this.resetPasswordLoading = true;
			this.$api.users.reset_password(this.id)
				.then(() => this.$toast.add('Password reset email sent', 'success'))
				.catch((error) => this.$toast.handleResponseErrors(this, error))
				.finally(() => this.resetPasswordLoading = false)
		}
	}
}
</script>